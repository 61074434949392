<template>
  <div>
    <a-select
      :value="selectedValue"
      :placeholder="placeholder"
      show-search
      allowClear
      style="width: 100%"
      :filter-option="filterOption"
      @change="changeValue"
    >
      <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id" :item="item">
        {{ item.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { equipmentCategoryOption } from "@/api/option";

export default {
  props: ["value", "placeholder"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      selectedValue: undefined,
      dataItems: [],
    };
  },
  methods: {
    filterOption(input, option) {
      const item = option.data.attrs.item;
      return item.name.indexOf(input) !== -1;
    },
    changeValue(value) {
      this.selectedValue = value;
      this.$emit("change", value);
    },
  },
  mounted() {
    if (this.value !== undefined) {
      this.selectedValue = this.value;
    }
    equipmentCategoryOption({ page_size: 999999 }).then((data) => (this.dataItems = data.results));
  },
};
</script>

<style scoped></style>
